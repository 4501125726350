<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-flex xs12>
        <v-btn flat icon color="error" @click="deleteDocument()">
          <v-icon>clear</v-icon>
        </v-btn>
        <span class="grey--text">{{ document.initialName }}</span>
      </v-flex>
      <v-text-field
        label="Nom du document"
        v-model="document.name"
        @change="updateDocument()"
        :rules="NameRules"
        required
      >
      </v-text-field>
      <v-combobox
        v-model="document.tags"
        :items="tagsExemples"
        label="Tags"
        multiple
        chips
      ></v-combobox>
      <v-text-field
        label="Type (exemple adf)"
        v-model="document.type"
        @change="updateDocument()"
      >
      </v-text-field>
      <v-switch
        label="Mode test (mode sans licence)"
        v-model="document.test"
        @change="updateDocument"
        messages="Utilisable seulement en environnement de production.
          Mode test automatique et obligatoire pour les autres environnement."
      ></v-switch>
      <v-select
        :items="[10, 15]"
        label="Version Prince XML"
        v-model="document.princeVersion"
        @change="updateDocument"
      ></v-select>
    </v-form>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    NameRules: [
      v => !!v || 'Le nom du document est requis.',
    ],
    tagsExemples: ['Exemple', 'Copro-Crastination', 'Copro-Vocation'],
  }),
  methods: {
    updateDocument() {
      this.$emit('documentUpdated', document);
    },
    deleteDocument() {
      this.$emit('documentDeleted', document);
    },
  },
  props: {
    document: Object,
    index: Number,
  },
};
</script>
