<template>
  <v-container fluid>
    <UploadFiles @newFileUploaded="handleNewFileUploaded"></UploadFiles>
    <v-layout column align-center>
      <v-flex xs12 v-if="uploadedDocuments.length === 0">
        <p class="text-xs-center">
          Veuillez glisser/déposer des fichiers HTML.<br>
          Encodage UTF-8 seulement.
        </p>
      </v-flex>
      <v-flex justify-center>
        <v-flex
          v-for="(document, index) in uploadedDocuments"
          :key="index"
        >
          <DocumentForm
            @documentUpdated="handleDocumentUpdated"
            @documentDeleted="handleDocumentDeleted"
            v-bind:document="document"
            v-bind:index="index"
          >
          </DocumentForm>
        </v-flex>
      </v-flex>
      <v-flex xs12>
        <v-btn
          :disabled="!isValid() || loading"
          color="primary"
          @click="send()"
          :loading="loading"
        >
          Envoyer
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import UploadFiles from '@/components/UploadFiles.vue';
import DocumentForm from '@/components/DocumentForm.vue';

export default {
  name: 'new',
  components: {
    UploadFiles,
    DocumentForm,
  },
  data: () => ({
    uploadedDocuments: [],
    loading: false,
  }),
  methods: {
    handleNewFileUploaded(newFile) {
      this.uploadedDocuments.push(newFile);
    },
    handleDocumentUpdated(value) {
      this.uploadedDocuments[value.index] = value.document;
    },
    handleDocumentDeleted(value) {
      this.uploadedDocuments.splice(value.index, 1);
    },
    isValid() {
      if (this.uploadedDocuments.length !== 0
        && !this.uploadedDocuments.find(f => f.name.length === 0)) {
        return true;
      }
      return false;
    },
    send() {
      this.loading = true;
      const promises = [];
      this.uploadedDocuments.forEach((document) => {
        const cleanDoc = document;
        delete cleanDoc.initialName;
        promises.push(this.$store.dispatch('documents/generateDocument', cleanDoc));
      });
      Promise.all(promises).then(() => {
        this.loading = false;
        this.setSnack('Tous les documents ont été générés.');
        this.$router.push('/list');
      }).catch(() => {
        this.loading = false;
        this.setSnack('Erreur pendant la génération des documents.');
        this.$router.push('/list');
      });
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
    }),
  },
};
</script>
