<template>
  <div style="visibility:hidden; opacity:0" id="dropzone">
    <div id="textnode">Glisser/déposer des fichiers.</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data: () => ({
    uploadedFiles: [],
  }),
  methods: {
    uploadFiles(f) {
      Object.keys(f).forEach((key) => {
        if (f[key].type !== 'text/html') {
          this.setSnack(`Fichier ${f[key].name} non pris en charge. HTML seulement`);
          return;
        } else if (this.uploadFiles.length > 0
          && this.checkDuplicateFile(f[key].name.substr(0, f[key].name.lastIndexOf('.html')))) {
          this.setSnack(`Fichier ${f[key].name} déjà importé.`);
          return;
        }
        const newFile = {
          name: f[key].name.substr(0, f[key].name.lastIndexOf('.html')) || f[key].name,
          initialName: f[key].name,
        };
        const reader = new FileReader();
        reader.onload = (event) => {
          newFile.htmlContent = event.target.result.replace(/\r?\n|\r/g, '');
          newFile.princeVersion = 10;
          this.$emit('newFileUploaded', newFile);
        };

        reader.readAsText(f[key], 'UTF-8');
      });
    },
    checkDuplicateFile(filename) {
      return !!this.uploadedFiles.find(file => file.fileName === filename);
    },
    ...mapMutations({
      setSnack: 'snackbar/setSnack',
    }),
  },
  mounted() {
    const self = this;

    window.addEventListener('dragenter', () => {
      document.querySelector('#dropzone').style.visibility = '';
      document.querySelector('#dropzone').style.opacity = 1;
      document.querySelector('#textnode').style.fontSize = '48px';
    });

    window.addEventListener('dragleave', (e) => {
      e.preventDefault();
      document.querySelector('#dropzone').style.visibility = 'hidden';
      document.querySelector('#dropzone').style.opacity = 0;
      document.querySelector('#textnode').style.fontSize = '42px';
    });

    window.addEventListener('dragover', (e) => {
      e.preventDefault();
      document.querySelector('#dropzone').style.visibility = '';
      document.querySelector('#dropzone').style.opacity = 1;
      document.querySelector('#textnode').style.fontSize = '48px';
    });

    window.addEventListener('drop', (e) => {
      e.preventDefault();
      document.querySelector('#dropzone').style.visibility = 'hidden';
      document.querySelector('#dropzone').style.opacity = 0;
      document.querySelector('#textnode').style.fontSize = '42px';

      const { files } = e.dataTransfer;
      self.uploadFiles(files);
    });
  },
};
</script>

<style lang="scss">
#file-panel {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
}

div#dropzone {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  transition: visibility 175ms, opacity 175ms;
  display: table;
  color: #fff;
  background: rgba(0, 0, 0, 0.45);
}

div#textnode {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  transition: font-size 175ms;
}
</style>
